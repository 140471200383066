/* סגנון רגיל לתצוגה */
.container {
  width: 100vw;
  margin: 0 auto;
  margin-top: 20vh;
}
.container img {
  width: 66vw;
  height: auto;
  margin-top: 4vh;
}

/* עיצוב עבור מסכים קטנים - מקסימום 1250px */
@media (max-width: 1250px) {
  .Pcontainer p {
    font-size: 2.4vw; /* התאם לפי הצורך */
  }
  .container img {
    width: 90vw;
    height: auto;
  }
}

.fc-daygrid-day.fc-daygrid-day-other {
  display: none;
}
