.container {
  display: inline-block;
  position: relative;

  top: 0;
  z-index: 100;

  margin-left: 10%;
  height: 16vh;
}
@media (max-width: 1250px) {
  .container {
    position: relative;
    right: 30%;
    top: 0;
    z-index: 100;

    height: 12vh;
  }
}
