.container img {
  width: 20vw;
  border-radius: 50%;
}

.container p {
  text-align: right;
  max-width: 46vw;
  font-size: 2vw;
  margin: 0 auto;
  padding: 10px;
}
.container div img {
  border-radius: 10px;
  width: 32vw;
}
.container div {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
  justify-content: center;
}

@media (max-width: 1250px) {
  .container p {
    font-size: 5vw;
    max-width: 72vw;
  }

  .container img {
    width: 60vw;
    border-radius: 50%;
  }
  .container div {
    display: flex;
    margin-top: 4vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container div img {
    width: 80vw;
  }
}
