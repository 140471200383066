body {
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-items: center;
}
h1 {
  margin-bottom: 2%;
  width: 100vw;
}
