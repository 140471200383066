/* Style for the sidebar container */
.container {
  display: none; /* Hide the container by default */
}

/* Style for the logo */
.logo {
  flex: 1; /* Allows the logo to take up available space */
  text-align: center; /* Center the logo horizontally */
}

/* Style for the navigation list */
.container ul {
  display: flex;
  flex-direction: column; /* Vertical layout for options */
  padding: 0;
  margin: 0; /* Reset margin */
  list-style-type: none; /* Remove bullet points */
  align-items: center; /* Center items horizontally */
}

/* Style for individual navigation items */
.container li {
  margin-bottom: 1rem; /* Space between items */
  font-size: 2vw; /* Adjust font size */
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Style for navigation links */
.container a {
  color: rgb(134, 72, 72);
  text-decoration: none; /* Remove underline */
  font-size: 18px;
}

/* Style for the active navigation item */
.active {
  color: rgb(95, 2, 2);
  border-radius: 6%;
  background-color: rgb(194, 73, 79);
  padding-left: 5px;
  padding-right: 5px;
}

/* Optional: Add hover effect */
.container li:hover {
  color: rgb(95, 2, 2); /* Change color on hover */
}

@media (max-width: 1250px) {
  .container {
    display: flex; /* Show the container */
    height: 10vh; /* Adjust height */
    width: 100%; /* Full width of the viewport */
    position: fixed; /* Fixed at the top */
    top: 0;
    background-color: #e1a0a0; /* Background color */
    box-shadow: 0 1px 5px rgba(30, 27, 27, 0.4); /* Shadow for the bar */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    z-index: 100; /* Ensure it is above other content */
    padding: 0 20px; /* Add some padding */
    flex-direction: row; /* Horizontal layout */
  }

  .logo {
    margin-right: auto;
  }

  .container .options {
    display: flex;
    align-items: center;
    background-color: #ffe8e6; /* Lighter pink for contrast */
  }

  .container svg {
    position: fixed;
    top: 2%;
    right: 4%;
  }

  .container ul {
    border-radius: 8px;
    background-color: #f4c3be; /* Soft pastel pink for the sidebar */
    list-style: none;
    position: fixed;
    padding: 0;
    top: 10vh;
    right: -40vw;
    width: 44vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }

  .container ul.visible {
    transform: translateX(0);
    opacity: 1;
    right: 0;
  }

  .container ul li {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    font-size: 14px;
    background-color: #ecafa7; /* Light coral pink for list items */
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    text-align: center;
    margin-right: 20px;
  }

  .container ul li:hover {
    background-color: #ffc4bf; /* Warmer pink for hover effect */
  }

  .container ul li.active {
    background-color: #eb978c; /* Highlighted active item */
    font-weight: bold;
  }

  .container ul a {
    color: #733d47; /* Warm plum color for text */
    text-decoration: none;
    width: 100%;
    display: block;
  }
}
