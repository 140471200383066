.container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  justify-content: center;
}

.container p {
  text-align: right;
  max-width: 44vw;
  font-size: 1.3vw;
  margin: 0 auto;
  padding: 10px;
}

.secondContainer {
  display: flex;
  flex-direction: column;
}

.container h2 {
  cursor: pointer;
}

.container img {
  cursor: pointer;
  width: 24vw;

  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1250px) {
  .container {
    margin-left: 0px;
  }
  .container img {
    margin-top: 2vh;
    width: 40vw;
    margin-left: 4vw;
    margin-right: 0px;
  }
}
