@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  
  margin: 0;
 font-weight: 400;

  font-family: 'Open Sans', sans-serif;
  
  font-optical-sizing: auto;
  font-style: normal;
background-color: #fce0da;
}
h2 {
  font-size: 26px;
}
@media(max-width: 1250px) {
  h2{
    font-size: 20px;
  }
}
p {
  direction: rtl;
}
li {
  direction: rtl;
}