.container {
  display: grid;
  align-items: center;
  grid-template-rows: repeat(2, 1fr);
  margin-top: 8%; /* This will center the container horizontally */

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14%;
}

.container p {
  font-size: 2vw;
}

@media (max-width: 1250px) {
  .container {
    width: 80vw;
    margin-top: 6%;
    margin-bottom: 32vh;
  }
  .container p {
    font-size: 5vw;
  }
}
