.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
  gap: 20px;
  margin-left: 30vw;
}
.container img {
  width: 30vw;
}

@media (max-width: 1250px) {
  .container img {
    width: 80vw;
  }
  .container {
    display: block;
    margin: 0;
  }
  .container img {
    margin-top: 2vh;
  }
}
